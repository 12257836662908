import { createAsyncThunk } from '@reduxjs/toolkit';
import { ActionsEnum } from '../../enums/actions.enum';
import { http } from '../../utils/http';
import { ENDPOINTS } from '../../constants/endpoints';
import { Job } from '../../interfaces/job.interface';

export const RETRIEVE_JOB_DETAILS = createAsyncThunk(
  ActionsEnum.RETRIEVE_JOB_DETAILS,
  async (jobPostId: string, thunkAPI): Promise<Job> => {
    let data;
    try {
      const response = await http(`${ ENDPOINTS.JOBS }/${ jobPostId }`, {
        method: 'GET'
      });
      data = response.data.data;
    } catch (e: any) {
      throw Error(e.response.data.error.message);
    }
    return data;
  }
);
