import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import FixedFooter from '../../../../components/ui/FixedFooter/FixedFooter';
import GoBack from '../../../../components/ui/GoBack/GoBack';
import InputGroup from '../../../../components/ui/inputs/InputGroup/InputGroup';
import InputPhone from '../../../../components/ui/inputs/InputPhone/InputPhone';
import InputText from '../../../../components/ui/inputs/InputText/InputText';
import ProgressBar from '../../../../components/ui/ProgressBar/ProgressBar';
import Subtitle from '../../../../components/ui/Subtitle/Subtitle';
import H2 from '../../../../components/ui/typography/headings/h2';
import useJob from '../../../../hooks/useJob';
import useWizard from '../../../../hooks/useWizard';

const OnboardingStepTwo = () => {
  const { t } = useTranslation();

  const { loaded } = useJob();

  const history = useHistory();

  const { jobPostId }: { jobPostId: string } = useParams();

  const {
    phone,
    email,
    phoneErrorMessage,
    emailErrorMessage,
    stepTwoIsValid,
    phoneChangeHandler,
    emailChangeHandler,
    stepTwoBlurHandler,
    stepTwoSubmitHandler,
  } = useWizard();

  useEffect(() => {
    if (jobPostId && !loaded) {
      history.push(`/${jobPostId}`);
    }
  }, [history, jobPostId, loaded]);

  const onStepTwoSubmit = () => {
    stepTwoSubmitHandler();
    history.push('step-3');
  };

  return (
    <Container>
      <GoBack />
      <ProgressBar value={100 / 2} />
      <H2 margin="20px 0">{t('stepTwo.title')}</H2>
      <Subtitle>{t('stepTwo.subtitle')}</Subtitle>
      <InputGroup>
        <InputPhone
          value={phone}
          error={phoneErrorMessage}
          onChange={phoneChangeHandler}
          onBlur={stepTwoBlurHandler}
        />
      </InputGroup>
      <InputGroup>
        <InputText
          name="email"
          placeholder={t('stepTwo.input.email')}
          disabled={false}
          value={email}
          error={emailErrorMessage}
          onChange={emailChangeHandler}
          onBlur={stepTwoBlurHandler}
        />
      </InputGroup>
      <FixedFooter
        disabled={!stepTwoIsValid}
        onClick={onStepTwoSubmit}
      />
    </Container>
  );
};

export default OnboardingStepTwo;

const Container = styled.div`
  padding: 20px 20px 100px 20px;
`;
