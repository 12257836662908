import { useDispatch, useSelector } from 'react-redux';
import {
  getOnboardingCV,
  getOnboardingEmail,
  getOnboardingLoading,
  getOnboardingName,
  getOnboardingPhone,
  getOnboardingStepOneCompleted,
  getOnboardingStepThreeCompleted,
  getOnboardingStepTwoCompleted,
  getOnboardingSurname
} from '../store/onboarding/selectors';
import { STEP_THREE_SUBMIT } from '../store/onboarding/thunk';
import React, { useEffect, useState } from 'react';
import { EMAIL_REGEX, PHONE_REGEX } from '../utils/regex';
import { STEP_ONE_SUBMIT, STEP_TWO_SUBMIT, SET_CV, UNSET_CV } from '../store/onboarding/actions';
import { useTranslation } from 'react-i18next';
import { PhoneInterface } from '../interfaces/common/phone.interface';
import { getJob } from '../store/job/selectors';

interface HookProps {
  loading: boolean;
  name?: string;
  surname?: string;
  phone: string;
  email?: string;
  cv?: File;
  cvRequired?: boolean;
  nameErrorMessage: string;
  surnameErrorMessage: string;
  phoneErrorMessage: string;
  emailErrorMessage: string;
  stepOneIsValid: boolean;
  stepTwoIsValid: boolean;
  stepThreeIsValid: boolean;
  stepOneCompleted: boolean;
  stepTwoCompleted: boolean;
  stepThreeCompleted: boolean;
  stepOneChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  stepOneBlurHandler: (e: React.FocusEvent<HTMLInputElement>) => void;
  phoneChangeHandler: (value: string) => void;
  emailChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  stepTwoBlurHandler: (e: React.FocusEvent<HTMLInputElement>) => void;
  fileChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  fileCancelHandler: () => void;
  stepOneSubmitHandler: () => void;
  stepTwoSubmitHandler: () => void;
  stepThreeSubmitHandler: (jobId: string) => void;
}

const useWizard = (): HookProps => {
  const { t } = useTranslation();

  /**
   * Hooks
   */
  const dispatch = useDispatch();

  /**
   * Selectors
   */
  const cvRequired: boolean | undefined = !!useSelector(getJob)?.cv_required;
  const loading: boolean = useSelector(getOnboardingLoading);
  const name: string | undefined = useSelector(getOnboardingName);
  const surname: string | undefined = useSelector(getOnboardingSurname);
  const mobilePhone: PhoneInterface | undefined = useSelector(getOnboardingPhone);
  const email: string | undefined = useSelector(getOnboardingEmail);
  const cv: File | undefined = useSelector(getOnboardingCV);
  const stepOneCompleted: boolean = useSelector(getOnboardingStepOneCompleted);
  const stepTwoCompleted: boolean = useSelector(getOnboardingStepTwoCompleted);
  const stepThreeCompleted: boolean = useSelector(getOnboardingStepThreeCompleted);

  /**
   * States
   */
  const [ stepOneFormState, setStepOneFormState ] = useState({
    name: { value: '', valid: false, touched: false },
    surname: { value: '', valid: false, touched: false }
  });
  const [ stepTwoFormState, setStepTwoFormState ] = useState({
    phone: { value: '', valid: false, touched: false },
    email: { value: '', valid: false, touched: false }
  });
  const [ stepThreeFormState, setStepThreeFormState ] = useState<File>();
  const [ stepOneIsValid, setStepOneIsValid ] = useState<boolean>(false);
  const [ stepTwoIsValid, setStepTwoIsValid ] = useState<boolean>(false);
  const [ stepThreeIsValid, setStepThreeIsValid ] = useState<boolean>(false);

  /**
   * Handlers
   */
  const stepOneChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => setStepOneFormState(((prevState) => {
    const newState: any = { ...prevState };
    newState[ e.target.name ].value = e.target.value;
    newState[ e.target.name ].valid = e.target.value.length >= 3;
    return { ...newState };
  }));
  const stepOneBlurHandler = (e: React.FocusEvent<HTMLInputElement>) => setStepOneFormState(((prevState) => {
    const newState: any = { ...prevState };
    newState[ e.target.name ].touched = true;
    return { ...newState };
  }));
  const phoneChangeHandler = (value: string) => setStepTwoFormState((prevState) => ({
    ...prevState,
    phone: {
      ...prevState.phone,
      value: value,
      valid: PHONE_REGEX.test(value) && value.length > 4
    }
  }));
  const emailChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => setStepTwoFormState((prevState) => ({
    ...prevState,
    email: {
      ...prevState.email,
      value: e.target.value.toLowerCase(),
      valid: EMAIL_REGEX.test(e.target.value)
    }
  }));
  const stepTwoBlurHandler = (e: React.FocusEvent<HTMLInputElement>) => setStepTwoFormState(((prevState) => {
    const newState: any = { ...prevState };
    newState[ e.target.name ].touched = true;
    return { ...newState };
  }));
  const fileChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setStepThreeFormState(e.target.files[ 0 ]);
      dispatch(SET_CV(e.target.files[ 0 ]));
    }
  };
  const fileCancelHandler = () => {
    setStepThreeFormState(undefined);
    dispatch(UNSET_CV());
  };

  /**
   * Submit handlers
   */
  const stepOneSubmitHandler = () => dispatch(STEP_ONE_SUBMIT({
    name: stepOneFormState.name.value,
    surname: stepOneFormState.surname.value
  }));
  const stepTwoSubmitHandler = () => dispatch(STEP_TWO_SUBMIT({
    phone: stepTwoFormState.phone.value,
    email: stepTwoFormState.email.value
  }));
  const stepThreeSubmitHandler = (jobId: string) => dispatch(STEP_THREE_SUBMIT({
    jobId,
    name,
    surname,
    mobilePhone,
    email,
    cv
  }));

  /**
   * Effects
   */
  useEffect(() => {
    setStepOneIsValid(stepOneFormState.name.valid && stepOneFormState.surname.valid);
  }, [ stepOneFormState ]);
  useEffect(() => {
    setStepTwoIsValid(stepTwoFormState.phone.valid && stepTwoFormState.email.valid);
  }, [ stepTwoFormState ]);
  useEffect(() => {
    setStepThreeIsValid(!!cv);
  }, [ stepThreeFormState, cv ]);

  /**
   * Hook properties
   */
  return {
    loading,
    name: stepOneFormState.name.value,
    surname: stepOneFormState.surname.value,
    phone: stepTwoFormState.phone.value,
    email: stepTwoFormState.email.value,
    cv: stepThreeFormState,
    cvRequired,
    nameErrorMessage: stepOneFormState.name.touched && !stepOneFormState.name.valid
      ? t('stepOne.input.name.error')
      : '',
    surnameErrorMessage: stepOneFormState.surname.touched && !stepOneFormState.surname.valid
        ? t('stepOne.input.surname.error')
        : '',
    phoneErrorMessage: stepTwoFormState.phone.touched && !stepTwoFormState.phone.valid
      ? t('stepTwo.input.phone.error')
      : '',
    emailErrorMessage: stepTwoFormState.email.touched && !stepTwoFormState.email.valid
      ? t('stepTwo.input.email.error')
      : '',
    stepOneIsValid,
    stepTwoIsValid,
    stepThreeIsValid,
    stepOneCompleted,
    stepTwoCompleted,
    stepThreeCompleted,
    stepOneChangeHandler,
    stepOneBlurHandler,
    phoneChangeHandler,
    emailChangeHandler,
    stepTwoBlurHandler,
    fileChangeHandler,
    fileCancelHandler,
    stepOneSubmitHandler,
    stepTwoSubmitHandler,
    stepThreeSubmitHandler
  };

};

export default useWizard;
