import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import FixedFooter from '../../../../components/ui/FixedFooter/FixedFooter';
import GoBack from '../../../../components/ui/GoBack/GoBack';
import InputGroup from '../../../../components/ui/inputs/InputGroup/InputGroup';
import InputText from '../../../../components/ui/inputs/InputText/InputText';
import ProgressBar from '../../../../components/ui/ProgressBar/ProgressBar';
import Subtitle from '../../../../components/ui/Subtitle/Subtitle';
import H2 from '../../../../components/ui/typography/headings/h2';
import useJob from '../../../../hooks/useJob';
import useWizard from '../../../../hooks/useWizard';

const OnboardingStepOne = () => {
  const { t } = useTranslation();

  const { loaded } = useJob();

  const history = useHistory();

  const { jobPostId }: { jobPostId: string } = useParams();

  const {
    name,
    surname,
    nameErrorMessage,
    surnameErrorMessage,
    stepOneIsValid,
    stepOneChangeHandler,
    stepOneBlurHandler,
    stepOneSubmitHandler,
  } = useWizard();

  useEffect(() => {
    if (jobPostId && !loaded) {
      history.push(`/${jobPostId}`);
    }
  }, [history, jobPostId, loaded]);

  const onStepOneSubmit = () => {
    stepOneSubmitHandler();
    history.push('step-2');
  };

  return (
    <Container>
      <GoBack />
      <ProgressBar value={100 / 3} />
      <H2 margin="20px 0">{t('stepOne.title')}</H2>
      <Subtitle>{t('stepOne.subtitle')}</Subtitle>
      <InputGroup>
        <InputText
          name="name"
          placeholder={t('stepOne.input.name')}
          disabled={false}
          value={name}
          error={nameErrorMessage}
          onChange={stepOneChangeHandler}
          onBlur={stepOneBlurHandler}
        />
      </InputGroup>
      <InputGroup>
        <InputText
          name="surname"
          placeholder={t('stepOne.input.surname')}
          disabled={false}
          value={surname}
          error={surnameErrorMessage}
          onChange={stepOneChangeHandler}
          onBlur={stepOneBlurHandler}
        />
      </InputGroup>
      <FixedFooter
        disabled={!stepOneIsValid}
        onClick={onStepOneSubmit}
      />
    </Container>
  );
};

export default OnboardingStepOne;

const Container = styled.div`
  padding: 20px 20px 100px 20px;
`;
