import { createAsyncThunk } from '@reduxjs/toolkit';
import { ActionsEnum } from '../../enums/actions.enum';
import { http } from '../../utils/http';
import { ENDPOINTS } from '../../constants/endpoints';
import { setErrorMessage, setSuccessMessage } from '../popup/actions';
import { StepThreeSubmitPayloadInterface } from '../../interfaces/XHRPayload/stepThreeSubmitPayload.interface';
import i18n from '../../utils/i18n';

export const STEP_THREE_SUBMIT = createAsyncThunk(
  ActionsEnum.SUBMIT_STEP_THREE,
  async ({ jobId, name, surname, mobilePhone, email, cv }: StepThreeSubmitPayloadInterface, thunkAPI) => {
    try {
      const formData = new FormData();
      if (name && surname && mobilePhone && email) {
        formData.append('name', name);
        formData.append('surname', surname);
        formData.append('mobilePhone', `${mobilePhone.prefix}${mobilePhone.number}`);
        formData.append('email', email);
      }
      if (cv) {
        formData.append('cv', cv);
      }
      await http(`${ ENDPOINTS.JOBS }/${ jobId }/applications`, {
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data: formData
      });
    } catch (e: any) {
      e?.response?.data?.error?.code === '035'
        ? thunkAPI.dispatch(setErrorMessage(i18n.t('stepThree.error.alreadyPresent')))
        : thunkAPI.dispatch(setErrorMessage(i18n.t('stepThree.error.generic')));
      throw Error(e.response.data.error.message);
    }
    thunkAPI.dispatch(setSuccessMessage(i18n.t('stepThree.success.message')))
    return true;
  }
);
