import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import CurriculumVitaeIcon from '../../../../assets/icons/CurriculumVitaeIcon';
import FixedFooter from '../../../../components/ui/FixedFooter/FixedFooter';
import GoBack from '../../../../components/ui/GoBack/GoBack';
import ProgressBar from '../../../../components/ui/ProgressBar/ProgressBar';
import Subtitle from '../../../../components/ui/Subtitle/Subtitle';
import H2 from '../../../../components/ui/typography/headings/h2';
import useJob from '../../../../hooks/useJob';
import useWizard from '../../../../hooks/useWizard';

const OnboardingStepThree = () => {
  const { t } = useTranslation();

  const { loaded } = useJob();

  const history = useHistory();

  const { jobPostId }: { jobPostId: string } = useParams();

  const {
    loading,
    cv,
    cvRequired,
    stepThreeIsValid,
    fileChangeHandler,
    fileCancelHandler,
    stepThreeSubmitHandler,
  } = useWizard();

  useEffect(() => {
    if (jobPostId && !loaded) {
      history.push(`/${jobPostId}`);
    }
  }, [history, jobPostId, loaded]);

  useEffect(() => {
    if (!cvRequired) {
      stepThreeSubmitHandler(jobPostId)
      history.push('greetings');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const buttonClickHandler = () => {
    stepThreeSubmitHandler(jobPostId)
    history.push('greetings')
  }

  return (
    <Container>
      <GoBack />
      <ProgressBar value={100} />
      <H2 margin="20px 0">{t('stepThree.title')}</H2>
      <Subtitle>{t('stepThree.subtitle')}</Subtitle>
      {!stepThreeIsValid && (
        <IconContainer>
          <CurriculumVitaeIcon />
          <small>{t('stepThree.input.cv')}</small>
          <FileInput
            type="file"
            name="cv"
            accept="application/pdf"
            onChange={fileChangeHandler}
          />
        </IconContainer>
      )}
      {stepThreeIsValid && (
        <SelectedFileContainer>
          <SelectedFileText>{cv?.name}</SelectedFileText>
          <SelectedFileCancel onClick={fileCancelHandler}>
            <svg
              width="16"
              height="18"
              viewBox="0 0 16 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.8029 5.69691C12.0655 5.95945 12.0655 6.38512 11.8029 6.64767L5.14766 13.303C4.88512 13.5655 4.45945 13.5655 4.19691 13.303C3.93436 13.0404 3.93436 12.6148 4.19691 12.3522L10.8522 5.69691C11.1147 5.43436 11.5404 5.43436 11.8029 5.69691Z"
                fill="#BBC4D4"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.19706 5.69701C4.45961 5.43447 4.88527 5.43447 5.14782 5.69701L11.8031 12.3523C12.0656 12.6149 12.0656 13.0405 11.8031 13.3031C11.5405 13.5656 11.1149 13.5656 10.8523 13.3031L4.19706 6.64777C3.93452 6.38523 3.93452 5.95956 4.19706 5.69701Z"
                fill="#BBC4D4"
              />
            </svg>
          </SelectedFileCancel>
        </SelectedFileContainer>
      )}
      <FixedFooter
        disabled={loading || !stepThreeIsValid}
        loading={loading}
        onClick={buttonClickHandler}
      />
    </Container>
  );
};

export default OnboardingStepThree;

const Container = styled.div`
  padding: 20px 20px 100px 20px;
`;

const IconContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  color: #c0c0c0;
`;

const FileInput = styled.input`
  -webkit-appearance: none;
  background-color: transparent;
  color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
`;

const SelectedFileContainer = styled.div`
  margin-top: 30px;
  padding: 15px;
  background-color: #f3f5fa;
  color: #2979ff;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SelectedFileText = styled.p`
  font-size: 0.8rem;
  margin: 0;
`;

const SelectedFileCancel = styled.div`
  width: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
