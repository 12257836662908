import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import ClockIcon from '../../assets/icons/ClockIcon';
import MapCursorIcon from '../../assets/icons/MapCursorIcon';
import JobDescriptionReader from '../../components/JobDescriptionReader/JobDescriptionReader';
import FixedFooter from '../../components/ui/FixedFooter/FixedFooter';
import Loader from '../../components/ui/Loader/Loader';
import Subtitle from '../../components/ui/Subtitle/Subtitle';
import H1 from '../../components/ui/typography/headings/h1';
import Small from '../../components/ui/typography/text/Small';
import Strong from '../../components/ui/typography/text/Strong';
import useJob from '../../hooks/useJob';
import { RETRIEVE_JOB_DETAILS } from '../../store/job/thunk';
import NotFound from '../NotFound/NotFound';

const Onboarding = () => {
  const { t } = useTranslation();

  const history = useHistory();

  const dispatch = useDispatch();

  const { jobPostId }: { jobPostId: string } = useParams();

  const { loading, loaded, error, job } = useJob();

  useEffect(() => {
    if (jobPostId && !loaded) {
      dispatch(RETRIEVE_JOB_DETAILS(jobPostId));
    }
  }, [dispatch, jobPostId, loaded]);

  if (!jobPostId || error) {
    return <NotFound />;
  }

  const clickHandler = () => history.push(`${jobPostId}/step-1`);

  return (
    <Container>
      {loading && <Loader />}
      {!loading && loaded && (
        <>
          <TitleContainer>
            <H1 margin="0 0 .67rem 0">{job?.job_name}</H1>
          </TitleContainer>
          <Company>{job?.company_name}</Company>
          <Subtitle>{t('onboarding.subtitle.info')}</Subtitle>
          <Card>
            {job && job.location && (
              <CardSection>
                <ColumnIcon>
                  <MapCursorIcon />
                </ColumnIcon>
                <ColumnText>
                  <Small
                    margin="0 0 10px 0"
                    color="#999999"
                  >
                    {t('onboarding.location')}
                  </Small>
                  <Strong margin="0">{job.location}</Strong>
                </ColumnText>
              </CardSection>
            )}
            {job && job.contract && (
              <CardSection>
                <ColumnIcon>
                  <ClockIcon />
                </ColumnIcon>
                <ColumnText>
                  <Small
                    margin="0 0 10px 0"
                    color="#999999"
                  >
                    {t('onboarding.workingHours')}
                  </Small>
                  <Strong margin="0">{job.contract}</Strong>
                </ColumnText>
              </CardSection>
            )}
          </Card>
          <Subtitle>{t('onboarding.subtitle.description')}</Subtitle>
          {/* Legacy description: old job posts prior to Quill integration */}
          {job && typeof job.description === 'string' && (
            <Card>
              <CardDescriptionSection>
                <ColumnText
                  dangerouslySetInnerHTML={{
                    __html: job && job.description ? job.description : '',
                  }}
                />
              </CardDescriptionSection>
            </Card>
          )}
          {/* Job description with Quill's Delta format */}
          {job && typeof job.description !== 'string' && (
            <JobDescriptionReader value={job.description} />
          )}

          <FixedFooter
            disabled={false}
            onClick={clickHandler}
          />
        </>
      )}
    </Container>
  );
};

export default Onboarding;

const Container = styled.div`
  padding: 20px 20px 100px 20px;
`;

const TitleContainer = styled.div`
  margin: 0 0 20px 0;
`;

const Company = styled.p`
  margin-bottom: 30px;
  font-size: ${(props) => props.theme.typography.subtitles.fontSize};
  font-weight: ${(props) => props.theme.typography.subtitles.fontWeight};
  color: ${(props) => props.theme.ui.company.color};
`;

const Card = styled.div`
  margin-bottom: 30px;
  border-radius: 16px;
  border: 1px solid ${(props) => props.theme.ui.goBack.borderColor};
  padding: 20px;
`;

const CardSection = styled.div`
  padding-bottom: 20px;
  border-bottom: 1px solid ${(props) => props.theme.ui.goBack.borderColor};
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &:last-child {
    border-bottom: 0;
    padding-bottom: 0;
    padding-top: 20px;
  }
`;

const CardDescriptionSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 0.85rem;
  font-weight: 500;
  line-height: 20px;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 700;
  }

  ul {
    padding-left: 20px;
  }

  li {
    margin-bottom: 10px;
  }
`;

const ColumnIcon = styled.div`
  margin-right: 20px;
`;

const ColumnText = styled.div`
  display: flex;
  flex-direction: column;
`;
